// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import LocalTime from "local-time";
import Lightbox from 'ekko-lightbox';
import 'ekko-lightbox/dist/ekko-lightbox.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Birthday from 'birthday/birthday';
import Registration from 'registration/registration';
import ChatForm from 'chats/chat_form';
import ChatChannel from 'channels/chat_channel';
import SessionsNew from 'sessions/new';
import Likes from 'likes/likes';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var channels = {};
var likes = new Likes();

$(document).on('turbolinks:load', function () {
  LocalTime.start();
  var birthday = new Birthday('[data-dob]');
  birthday.age();
  var reg = new Registration('#user_country', '#user_state');
  reg.bind();
  var chatFormSelector = '.input_msg_write form';
  var chatForm = new ChatForm(chatFormSelector);
  chatForm.bind();
  channels['chat'] = channels['chat'] || new ChatChannel(chatForm);
  if (chatForm.exists()) {
    channels['chat'].subscribe();
  } else {
    channels['chat'].unsubscribe();
    channels['chat'] = null;
  }
  var sessions_new = new SessionsNew('#session_new', '#errors');
  sessions_new.bind();
  $('[data-toggle="lightbox"]').on('click', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox({
      showArrows: true,
      wrapping: true,
      alwaysShowClose: true
    });
  });
  likes.bind();
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on('turbolinks:before-cache', () => {
  // Manually tear down bootstrap modals before caching. If turbolinks
  // caches the modal then tries to restore it, it breaks bootstrap's JS.
  // We can't just use bootstrap's `modal('close')` method because it is async.
  // Turbolinks will cache the page before it finishes running.
  if (document.body.classList.contains('modal-open')) {
    $('.ekko-lightbox').remove();
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
});
