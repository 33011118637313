import moment from 'moment';

class ReadReceipt {
  constructor(data) {
    this.unreadTimeSelector = '.bi.bi-check-circle';
  }

  update(data) {
    const self = this;
    $(this.unreadTimeSelector).each(function(index) {
      if (self.currentUserId() !== data['user_id']) {
        $(this).removeClass('bi-check-circle');
        $(this).addClass('bi-check-circle-fill');
      }
    });
  }

  currentUserId() {
    return parseInt($('#message_user_id').val(), 10);
  }
}

export default ReadReceipt;
