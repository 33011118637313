import consumer from "./consumer"
import ChatForm from "../chats/chat_form"
import ReadReceipt from "../chats/read_receipt"

class ChatChannel {
  constructor(chatForm) {
    this.chatForm = chatForm;
    this.subscription = null;
    this.keepAlive = null;
    this.readReceipt = new ReadReceipt;
  }

  subscribe () {
    const self = this;
    this.subscription = consumer.subscriptions.create({channel: "ChatChannel", id: self.chatForm.chatId()}, {
      received(data) {
        if (data['type'] === 'Message') {
          self.chatForm.append(data);
          self.chatForm.scrollBottom();
        } else if (data['type'] === 'ReadReceipt') {
          self.readReceipt.update(data);
        }
      },
      ping: function() {
        return this.perform('ping', {
          message: {"event": "ping"}
        });
      },
      watching: function () {
        return this.perform('watching');
      }
    })
    self.keepAlive = setInterval(function(){
      self.subscription.ping();
    }, 30000);
    self.watching = setInterval(function () {
      if (document.visibilityState === 'visible') {
        self.subscription.watching();
      }
    }, 30000)
  }

  unsubscribe () {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
      this.subscription = null;
      if (this.keepAlive) {
        clearInterval(this.keepAlive);
      }
      if (this.watching) {
        clearInterval(this.watching);
      }
    }
  }
}

export default ChatChannel;
