import moment from 'moment';

class Birthday {
  constructor(selector) {
    this.selector = selector;
  }

  age() {
    $(this.selector).each(function (index, ele) {
      var datetimeString = $(ele).data('dob');
      var datetime = moment(datetimeString);
      var age = moment().diff(datetime, 'years');
      $(ele).html(age);
    });
  }
}

export default Birthday;
