import moment from 'moment';

class ChatForm {
  constructor(selector) {
    this.selector = selector;
    this.msgHistorySelector = '.msg_history';
    this.chatTextBox = '.input_msg_write .write_msg';
    this.currentUserIdSelector = '#message_user_id';
  }

  myMessageHtml(data) {
    var time = moment(data.created_at).format('MMMM DD, YYYY h:mma');
    var circle = this.premium() ? '<i class="bi bi-check-circle"></i>' : '';
    return `
      <div class="outgoing_msg mb-0">
        <div class="sent_msg row">
          <p class="show-white-space">${data.content}</p>
        </div>
      </div>
      <div class="row p-0 m-0 mt-0 pt-0 pr-3" style="float:right;">
        <span class="time_date">${time} ${circle}</span>
      </div>
    `
  }

  otherMessageHtml(data) {
    var time = moment(data.created_at).format('MMMM DD, YYYY h:mma');
    return `
      <div class="incoming_msg">
        <div class="received_msg">
          <div class="received_withd_msg">
            <p class="show-white-space">${data.content}</p>
          </div>
        </div>
        <span class="time_date">${time}</span>
      </div>
    `
  }

  scrollBottom() {
    $(this.msgHistorySelector).scrollTop($(this.msgHistorySelector)[0].scrollHeight);
  }

  append(data) {
    var html;
    if (this.currentUserId() == data.user_id) {
      html = this.myMessageHtml(data);
    } else {
      html = this.otherMessageHtml(data);
    }
    $(this.msgHistorySelector).append(html)
  }

  exists() {
    return $(this.selector).length > 0;
  }

  chatId() {
    return $('#message_chat_id').val();
  }

  currentUserId() {
    return parseInt($('#message_user_id').val(), 10);
  }

  premium() {
    return $('#message_premium').val() == 'true';
  }

  bind() {
    var self = this;
    if (self.exists()) {
      self.scrollBottom();
      $(self.selector).on("ajax:success", (event) => {
        const [data, status, xhr] = event.detail;
        $(this.chatTextBox).val('')
        $(this.chatTextBox).prop("disabled", false)
      }).on("ajax:beforeSend", (event) => {
        $(this.chatTextBox).prop("disabled", true)
        return true;
      }).on("ajax:error", (event) => {
        $(this.chatTextBox).prop("disabled", false)
      });
    }
  }
}

export default ChatForm;
