class Registration {
  constructor(countrySelector, stateSelector) {
    this.countrySelector = countrySelector;
    this.stateSelector = stateSelector;
  }

  bind() {
    var self = this;
    $(this.countrySelector).on('change', function () {
      var countryCode = $(this).val();
      $.ajax({
        method: 'GET',
        url: "/api/v1/states?country_code=" + countryCode,
      }).done(function(data) {
        $(self.stateSelector).html('');
        $.each(data, function(index, state) {
          var code = Object.keys(state)[0];
          var name = state[code];
          var html = '<option value="' + code + '">' + name +'</option>';
          $(self.stateSelector).append(html);
        });
      });
    });
  }
}

export default Registration;
