class SessionsNew {
  constructor(formSelector, errorSelector) {
    this.formSelector = formSelector;
    this.errorSelector = errorSelector;
    this.bound = false;
  }

  bind() {
    var self = this;
    if (self.bound === false) {
      $(self.formSelector).on('ajax:error', (event) => {
        const [data, status, xhr] = event.detail;
        $(self.errorSelector).html('<p>' + data + '</p>');
      });
      self.bound = true;
    }
  }
}

export default SessionsNew;
